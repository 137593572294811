'use client';

import { FC, Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { BriefcaseIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useModalContext } from '@/context/modalContext';
import { useCreateSubWorkspaceMutation } from '@/mutations/useCreateSubWorkspaceMutation';

import Button from '../button.component';
import Input from '../input.component';

const schema = yup.object().shape({
  name: yup.string().required('This field required').max(512),
});

type FormData = {
  name: string;
};

type CreateWorkspaceModalProps = {
  id: string;
};

function CreateWorkspaceModal({ id }: CreateWorkspaceModalProps) {
  const router = useRouter();
  const { data: session } = useSession();
  const { activeModal, closeModal } = useModalContext();
  const createSubWorkspace = useCreateSubWorkspaceMutation();
  const onSuccess = activeModal.data?.onSuccess;

  const user = session?.user as any;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const isActive = activeModal.id === id;

  const onSubmit = async (values: FormData) => {
    if (!user?.workspace?.id) return;

    const result = await createSubWorkspace.mutateAsync({
      name: values.name,
      parentId: user.workspace.id,
    });

    if (result) {
      closeModal();
      reset();
      router.refresh();
      router.push(`/create`);
      onSuccess?.();
    }
  };

  return (
    <Transition.Root show={isActive} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                {/* Close button */}
                <button
                  onClick={closeModal}
                  className="absolute right-6 top-6 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                <div className="flex flex-col items-center justify-center bg-white px-10 py-6">
                  {/* Icon */}
                  <div className="mb-6 rounded-full bg-cyan-100 p-3">
                    <BriefcaseIcon className="h-8 w-8 text-cyan-600" />
                  </div>

                  {/* Title and content adjustments */}
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Create workspace
                  </Dialog.Title>

                  <p className="mt-2 text-center text-sm text-gray-600">
                    Create new workspace for your customer and manage team
                    members.
                  </p>

                  <form
                    className="mt-5 w-full space-y-6"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {/* Form content adjustments */}
                    <div>
                      <label className="mb-2 block text-left text-xs font-medium text-gray-900">
                        Workspace Name
                      </label>
                      <Input
                        className="w-full rounded-lg text-gray-600"
                        placeholder="Enter workspace name"
                        {...register('name')}
                        error={errors.name}
                      />
                    </div>

                    <div className="flex gap-3">
                      <Button
                        className="max-w-[81px] rounded-lg border border-gray-300 bg-white text-gray-700 hover:bg-gray-50"
                        type="button"
                        variant="light"
                        onClick={closeModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="flex-1 rounded-lg bg-black text-white hover:bg-gray-900"
                        type="submit"
                        variant="dark"
                        isLoading={createSubWorkspace.isLoading}
                      >
                        Save
                      </Button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default CreateWorkspaceModal;
