'use client';

import { usePathname } from 'next/navigation';

import { GTM_ID } from '@/utils/gtm';

const GTMBodyScript = () => {
  const pathname = usePathname();

  if (pathname?.includes('aibot-iframe')) return null;
  if (pathname?.includes('generator-ai')) return null;
  if (pathname?.includes('free-tools')) return null;
  if (pathname?.includes('privacy-policy-summarizer')) return null;
  if (pathname?.includes('api-pricing-calculator')) return null;
  if (pathname?.includes('create-whatsapp-links')) return null;
  if (pathname?.includes('page-speed-insights')) return null;

  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
        height="0"
        width="0"
        className="invisible hidden"
      />
    </noscript>
  );
};

export default GTMBodyScript;
