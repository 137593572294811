'use client';

import { ReactNode } from 'react';

import { SessionProvider } from 'next-auth/react';

type Props = {
  children?: ReactNode;
};

const NextAuthProvider = ({ children }: Props) => {
  return (
    <SessionProvider refetchOnWindowFocus={true}>{children}</SessionProvider>
  );
};

export default NextAuthProvider;
