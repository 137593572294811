import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/app/gtm-body-script.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/app/gtm-head-script.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/app/next-auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogProvider"] */ "/vercel/path0/apps/web/src/app/post-hog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/app/session-checker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/app/wrapper/query-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/app/wrapper/tooltip-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/modals/create-workspace-modal.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/modals/upgrade-modal.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/sheet.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/toast-wrapper.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalProvider"] */ "/vercel/path0/apps/web/src/context/modalContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.4_@babel+core@7.25.9_@playwright+test@1.49.1_babel-plugin-macros@3.1.0_react-dom@19_qywzksjcgrk6daslbm2shn4qia/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.4_@babel+core@7.25.9_@playwright+test@1.49.1_babel-plugin-macros@3.1.0_react-dom@19_qywzksjcgrk6daslbm2shn4qia/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/styles/global.scss");
